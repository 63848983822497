import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import coverImg1 from  "../assets/img/front/yahya_glitch1.jpg";
import coverImg2 from "../assets/img/front/foto1_BW.jpeg";
import coverImg3 from "../assets/img/front/foto2_BW2.jpeg"
import coverImg4 from "../assets/img/front/yahya_dreamy1.jpeg"

const Home = () => {
  const onLeave = (origin, destination, direction) => {

    if (origin.index === 0) {
      const firstItem = origin.item
      firstItem.classList.remove('fullpage__section__first')
    }
  }
  return (
    <Layout>
      <SEO title="Brageteatret presenterer" />
      <main className="home-section">
        <ReactFullpage
          scrollOverflow
          onLeave={onLeave}
          licenseKey={process.env.GATSBY_FULLPAGE_KEY}
          sectionSelector=".fullpage__section"
          render={({ state, fullpageApi }) => (
            <div id="fullpage-wrapper" className="text-center">
              <section className="fullpage__section fullpage__section__first">
                <div className="max-w-md mx-auto md:px-4">
                  <div className="fullpage__text__animation">
                    <h3 className="font-maison-meue-book text-xl mb-10">
                      Brageteatret presenterer
                    </h3>
                    <h2 className="font-brand text-pink text-65 uppercase clip-image">
                      SKUDDSIKKER <br /> VEST
                    </h2>
                    <p className="text-base pt-10">
                      Dikt fra forestillingen om
                      <br /> Yahya Hassans liv og
                      <br /> forfatterskap
                    </p>
                  </div>
                </div>
              </section>
              <section className="fullpage__section">
                <div className="max-w-md mx-auto h-full md:px-4">
                  <div className="h-full relative overflow-hidden">
                    <img
                      className="h-full w-full object-cover fullpage__img__animation animation-zoom-in"
                      src={coverImg4}
                      alt=""
                    />
                    <div className="absolute left-0 bottom-16 text-center w-full p-4 fullpage__text__animation">
                      <h2 className="font-demi text-4xl text-black leading-10 mb-10">
                        <span className="text-highlight"><em>YAHYA HASSAN.</em></span>
                      </h2>

                      <h2 className="font-demi text-4xl text-black leading-10 uppercase">
                      <span className="text-highlight"><em>HVEM VAR HAN</em></span>
                        <br />  <span className="text-highlight"><em>EGENTLIG?</em></span>
                      </h2>
                    </div>
                    <div className="absolute left-2 bottom-2 text-sm text-white">
                    ©: Mads Nissen/Ritzau/NTB
                    </div>
                  </div>
                </div>
              </section>
              <section className="fullpage__section">
                <div className="max-w-md mx-auto h-full md:px-4">
                  <div className="h-full relative">
                    <img
                      className="h-full w-full object-cover fullpage__img__animation"
                      src={coverImg2}
                      alt=""
                    />
                    <div className="absolute left-0 bottom-14 text-center w-full p-4 fullpage__text__animation">
                      <h2 className="font-demi text-4xl uppercase leading-[3rem] highlight-text-outer">
                        <span className="delay-500">
                        EN UNG DANSK-
                        <br />
                        PALESTINSK POET
                        <br /> MED ET</span> <span className="highlight-text">KRAFTFULLT</span>
                        <br /> <span className="delay-500">OG</span> <span className="highlight-text">
                          ÆRLIG SPRÅK.</span> 
                      </h2>
                    </div>
                    <div className="absolute left-2 bottom-2 text-sm text-white">
                    ©: Jacob Ehrbahn/Polfoto/AP/NTB
                    </div>
                  </div>
                </div>
              </section>

              <section className="fullpage__section">
                <div className="max-w-md mx-auto md:px-4f h-full flex justify-center items-center ">
                  <h2 className="font-demi text-4xl leading-[3rem] uppercase leading-[3rem] fullpage__text__animation highlight-text-outer">
                  <span className="delay-500">SOM SKREV OM EN
                    <br /> OPPVEKST PREGET
                    <br /> AV</span> <span className="highlight-text">INSTiTUSJONER
                    <br /> VOLD</span> <span className="delay-500">OG</span>
                    <br /> <span className="highlight-text">kriminalitet.</span>
                  </h2>
                </div>
              </section>

              <section className="fullpage__section">
                <div className="max-w-md mx-auto h-full md:px-4">
                  <div className="h-full relative gradient-bg">
                    <img
                      className="h-full w-full object-cover fullpage__img__animation"
                      src={coverImg3}
                      alt=""
                    />
                    <div className="absolute left-0 bottom-12 text-center w-full p-4 z-10">
                      <h2 className="font-demi text-4xl leading-10 uppercase leading-[3rem] fullpage__text__animation highlight-text-outer">
                        <span className="delay-500">Med</span> <span className="highlight-text">bestselgende</span>
                        <br /> <span className="delay-500">diktsamling og en</span>
                        <br /> <span className="highlight-text">NOMINASJON</span> <span className="delay-500">til
                        <br /> Nordisk råds</span>
                        <br /> <span className="highlight-text">litteraturpris.</span>
                      </h2>
                    </div>
                    <div className="absolute left-2 bottom-2 text-sm text-white z-50">
                    ©: Martin Hansen, Gyldendal Medie, 2019
                    </div>
                  </div>
                </div>
              </section>

              <section className="fullpage__section">
                <div className="max-w-md mx-auto h-full md:px-4">
                  <div className="h-full relative overflow-hidden">
                    <img
                      className="h-full w-full object-cover fullpage__img__animation animation-zoom-in"
                      src={coverImg1}
                      alt=""
                    />
                    <div className="absolute left-0 bottom-12 text-center w-full p-4">
                      <h2 className="font-demi text-4xl sm-text-2xl leading-10 uppercase text-black leading-[3rem] mb-0 pb-0">
                        <span className="text-highlight"><em>Og et liv som</em></span><br />
                        <span className="text-highlight"><em>dessverre ble for</em></span><br />
                        <span className="text-highlight inline-block"><em>kort.</em></span>
                      </h2>
                    </div>
                    <div className="absolute left-2 bottom-2 text-sm text-white z-30">
                    ©: Ida Guldbæk Arentsen/Ritzau/NTB
                    </div>
                  </div>
                </div>
              </section>

              <section className="fullpage__section">
                <div className="max-w-md mx-auto md:px-4 h-full flex justify-center items-center">
                  <div className="fullpage__text__animation">
                    <h2 className="font-demi text-4xl uppercase highlight-text-outer">
                    <span className="delay-500">Yahya hassan
                      <br /> ble</span> <span className="highlight-text">funnet død</span>
                      <br /> <span className="delay-500">29. April 2020.</span>
                    </h2>
                    <h2 className="font-demi text-4xl uppercase mt-12 highlight-text-outer">
                    <span className="delay-500">Bare</span> <span className="highlight-text">24 år
                      <br /> gammel</span>
                    </h2>
                  </div>
                </div>
              </section>

              <section className="fullpage__section">
                <div className="max-w-md mx-auto md:px-4 h-full flex justify-center items-center">
                  <div className="mask-bg fullpage__text__animation flex flex-col items-center justify-center p-4 relative">
                    <p className="text-xl text-white p-0 max-w-xs mx-auto">
                      Med Yahya Hassans
                      brutale poesi inviterer vi
                      deg inn i en nådeløs
                      verden med hasj, angst,
                      skuddsikker vest og
                      mangel på muligheter,
                      men også humor, rosa g-streng og håp.
                    </p>
                    <Link
                      to="/dikt"
                      className="bg-brand px-5 py-3 inline-flex justify-center items-center text-2xl text-black uppercase font-demi absolute -bottom-5 left-2/4 transform -translate-x-2/4 w-64 no-underline animation-up-down"
                    >
                      <span>GÅ TIL DIKTENE</span>
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          )}
        />
      </main>
    </Layout>
  )
}

export default Home
